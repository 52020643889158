import React, { Component } from 'react';
import styled from 'styled-components'

class HomeGameItem extends Component {
    state = { backgroundImageUrl: '' } // tODO: use placeholder image at first
    componentDidMount() {
        const { getHomeImageUrl, homeImage } = this.props;
        
        getHomeImageUrl(homeImage).then(url => {
            this.setState({ backgroundImageUrl: url});
        });
        //var pathReference = storage.ref('homeImages/fate-of-humans.png');

        // storageRef.child('images/stars.jpg').getDownloadURL().then(function(url) {
        //     // `url` is the download URL for 'images/stars.jpg'
          
        //     // This can be downloaded directly:
        //     var xhr = new XMLHttpRequest();
        //     xhr.responseType = 'blob';
        //     xhr.onload = function(event) {
        //       var blob = xhr.response;
        //     };
        //     xhr.open('GET', url);
        //     xhr.send();
          
        //     // Or inserted into an <img> element:
        //     var img = document.getElementById('myimg');
        //     img.src = url;
        //   }).catch(function(error) {
        //     // Handle any errors
        //   });
    }
    render() {
        const {
            onClick,
        } = this.props;
        //const backgroundImage = images(`./home/${homeImage}`);
        return (
            <HomeGameImage onClick={onClick} src={this.state.backgroundImageUrl}>
            </HomeGameImage>
        );
    }
}

const HomeGameImage = styled.div`
    background-image: url('${props => props.src}');
    width: 100%;
    background-size: cover;
    background-position: center;
`;

export default HomeGameItem;