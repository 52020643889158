import React, { Component } from 'react';
import logoSquareImage from "./images/logo/logo-square.png";
import envelopeImage from "./images/envelope.png";
import styled, { keyframes } from 'styled-components'
import * as firebase from 'firebase';

import HomeGameItem from './home/HomeGameItem';

const screenSizes = {
    DEFAULT: 'default',
    SMALL: 'small',
    LARGE: 'large'
}

const gridSize = {
    [screenSizes.SMALL]: {
        c: 3,
        r: 4
    },
    [screenSizes.DEFAULT]: {
        c: 4,
        r: 4
    },
    [screenSizes.LARGE]: {
        c: 4,
        r: 3
    }
}

const currentScreenSize = () => {
    if (window.innerWidth < 800) return screenSizes.SMALL;
    if (window.innerWidth >= 1440) return screenSizes.LARGE;
    return screenSizes.DEFAULT;
}

class App extends Component {
    state = {
        isLoading: true,
        screenSize: currentScreenSize()
    }

    componentDidMount() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
        })
        window.addEventListener('resize', this.onWindowResize);
        this.updateScreenSize()

        this.getGames()
            .then(response => response.json())
            .then(games => {
                const gamesList = [undefined]
                gamesList.push.apply(gamesList, Object.keys(games).map(key => (games[key])));
                this.setState({
                    games: gamesList,
                    isLoading: false
                })
            })
    }

    updateScreenSize = () => {
        this.setState({
            screenSize: currentScreenSize()
        })
    }

    onWindowResize = () => {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
        })
        this.updateScreenSize()
    }

    getGames() {
        return fetch('https://nachos-games-web.firebaseio.com/games.json');
    }

    getHomeImageUrl(homeImageName) {
        var storage = firebase.storage();
        var storageRef = storage.ref();
        return storageRef.child(`homeImages/${homeImageName}`).getDownloadURL()
    }

    randomGame() {
        var randomIndex = Math.floor(Math.random() * this.state.games.length);
        if (randomIndex === 0)
            randomIndex = 1;
        return this.state.games[randomIndex]
    }
    
    render() {
        const { games, isLoading } = this.state

        if (isLoading) {
            return (<LoadingLogoContainer>
                <LoadingLogo src={logoSquareImage} />
            </LoadingLogoContainer>);
        }

        const { c: columns, r:rows } = gridSize[this.state.screenSize]
        //const { width, height } = this.state

        const columnsWidthPercentage = 100 / columns + "%"
        var divs = []
        for (var i = 0; i < rows; i++) {
            for (var j = 0; j < columns; j++) {
                if (i === 0 && j === 0) {
                    divs.push(<GridCell
                        key={i + '' + j}
                        flexWidthPercent={columnsWidthPercentage}>
                            <LogoImage src={logoSquareImage}>
                                <MailIcon src={envelopeImage}></MailIcon>
                            </LogoImage>
                        </GridCell>)
                    continue;
                }

                var game = games[i * columns + j]
                if (!game)
                    game = this.randomGame()
                divs.push(
                    <GridCell
                        key={i + '' + j}
                        flexWidthPercent={columnsWidthPercentage}
                        color={Math.floor(Math.random()*16777215).toString(16)}>
                            <HomeGameItem homeImage={game.homeImage} getHomeImageUrl={this.getHomeImageUrl} />
                    </GridCell>)
            }
        }

        return (<Grid>{divs}</Grid>)
    }
}

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
`;
const GridCell = styled.div`
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: ${props => props.flexWidthPercent};
`;

// background-color: #${props => props.color};

const LoadingLogoContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const pulse = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1
  }
`;  

const LoadingLogo = styled.div`
  background-image: url('${props => props.src}');
  width: 50%;
  height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex: 1;
  animation: ${pulse} 1s infinite;
`;


// const Logo = styled.div`
//   background-image: url('${props => props.src}');
//   width: 50%;
//   height: 200px;
//   background-position: center;
//   background-size: contain;
//   background-repeat: no-repeat;
//   display: flex;
//   flex: 1;
// `;

const LogoImage = styled.div`
    background-image: url('${props => props.src}');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;
`;

const MailIcon = styled.div`
    position: absolute;
    width: 100%;
    margin-top: 150px;
    background-image: url('${props => props.src}');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;


export default App;